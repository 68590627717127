import { curryAppSelector } from "@/store/reselect";
import {
  neutral,
  NoTranslate,
  SingleSelectItemProps,
  TruncatedFaroText,
} from "@faro-lotv/flat-ui";
import {
  GUID,
  IElementGenericImgSheet,
  isIElementAreaSection,
  isIElementOverviewImage,
} from "@faro-lotv/ielement-types";
import { selectAncestor, selectIElement } from "@faro-lotv/project-source";
import { Box, Divider } from "@mui/material";
import { createSelector } from "@reduxjs/toolkit";
import { ReactNode } from "react";
import { MultiLayerMenuItem } from "./multi-layer-menu-item";

type MultiLayerMenuItemGroupProps = Pick<
  SingleSelectItemProps,
  "selectedValue"
> & {
  /** The contents of the group */
  group: MultiLayerMenuItemGroup;

  /** Whether the item is the first one in the list */
  isFirstGroup: boolean;

  /** Whether there are more than 1 visible layers */
  isMoreThanOneVisibleSheet: boolean;
};

/**
 * @returns a group of layers with a title
 */
export function MultiLayerMenuItemGroup({
  group,
  isFirstGroup,
  selectedValue,
  isMoreThanOneVisibleSheet,
}: MultiLayerMenuItemGroupProps): JSX.Element {
  return (
    <>
      {!isFirstGroup && (
        <Divider
          sx={{
            backgroundColor: neutral[800],
            // Important is needed to override a inherited style from being in a MuiListItem
            my: "0 !important",
          }}
        />
      )}
      <Box component="div" sx={{ p: 1.5 }}>
        <TruncatedFaroText
          variant="labelM"
          sx={{ textTransform: "uppercase" }}
          dark
        >
          {group.title}
        </TruncatedFaroText>
      </Box>
      {group.layers.map((sheet) => (
        <MultiLayerMenuItem
          sheet={sheet}
          key={sheet.id}
          selectedValue={selectedValue}
          isMoreThanOneVisibleSheet={isMoreThanOneVisibleSheet}
        />
      ))}
    </>
  );
}

type MultiLayerMenuItemGroup = {
  /** The title of the group */
  title: ReactNode;

  /** The layers to show in this group */
  layers: IElementGenericImgSheet[];
};

export const selectMultiLayerMenuItemGroups = curryAppSelector(
  createSelector(
    [(_state, sheets: IElementGenericImgSheet[]) => sheets, (state) => state],
    (sheets, state): MultiLayerMenuItemGroup[] => {
      // Map of areaId to sheets in that area
      const areaSheets = new Map<GUID, IElementGenericImgSheet[]>();
      const overviewMapSheets: IElementGenericImgSheet[] = [];
      // This currently shouldn't contain any sheets, but this makes sure that unexpected sheets still have a place to be shown.
      const otherSheets: IElementGenericImgSheet[] = [];

      for (const sheet of sheets) {
        if (isIElementOverviewImage(sheet)) {
          overviewMapSheets.push(sheet);
        } else {
          const area = selectAncestor(sheet, isIElementAreaSection)(state);

          if (area) {
            const existingGroup = areaSheets.get(area.id);
            if (existingGroup) {
              existingGroup.push(sheet);
            } else {
              areaSheets.set(area.id, [sheet]);
            }
          } else {
            otherSheets.push(sheet);
          }
        }
      }

      return [
        ...Array.from(areaSheets.entries()).map(([areaId, layers]) => ({
          title: (
            <NoTranslate component="span">
              {selectIElement(areaId)(state)?.name ?? areaId}
            </NoTranslate>
          ),
          layers,
        })),
        ...(overviewMapSheets.length
          ? [{ title: "Overview Maps", layers: overviewMapSheets }]
          : []),
        ...(otherSheets.length
          ? [{ title: "Other", layers: otherSheets }]
          : []),
      ];
    },
  ),
);
