import { selectControlPointsSheetElevation } from "@/store/modes/control-points-alignment-mode-selectors";
import {
  setControlPointsReferencePoint1,
  setControlPointsReferencePoint2,
  setControlPointsSheetElevation,
} from "@/store/modes/control-points-alignment-mode-slice";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import { FaroText, TextField, TruncatedFaroText } from "@faro-lotv/flat-ui";
import { assert } from "@faro-lotv/foundation";
import { GUID, isIElementAreaSection } from "@faro-lotv/ielement-types";
import { selectIElement } from "@faro-lotv/project-source";
import { Box, Stack } from "@mui/material";
import { debounce } from "es-toolkit";
import { useCallback, useRef, useState } from "react";
import { Vector3Tuple } from "three";

type ControlPointsAlignmentSetPointsPanelProps = {
  /** id of area or layer to align */
  layerOrAreaId: GUID;
};

/**
 * validate if 2 points have distance sufficient to compute alignment
 *
 * @param p1 point1 coordinates
 * @param p2 point2 coordinates
 * @returns true if case if points are too close. In case if one of both inputs are undefined returns false
 */
export function checkIfTwoPointsForAlignmentAreTooClose(
  p1?: Vector3Tuple,
  p2?: Vector3Tuple,
): boolean {
  if (!p1 || !p2) return false;

  // Minimal allowed distance in meters
  const minAllowedDistance = 0.01;

  const dx = p1[0] - p2[0];
  const dy = p1[2] - p2[2];
  return Math.sqrt(dx * dx + dy * dy) < minAllowedDistance;
}

/**
 * @returns panel to set control points and elevation
 */
export function ControlPointsAlignmentSetPointsPanel({
  layerOrAreaId,
}: ControlPointsAlignmentSetPointsPanelProps): JSX.Element {
  const dispatch = useAppDispatch();
  const store = useAppStore();

  const [validationErrorText, setValidationErrorText] = useState<
    string | undefined
  >();

  const elementToAlign = selectIElement(layerOrAreaId)(store.getState());
  assert(
    elementToAlign && isIElementAreaSection(elementToAlign),
    "Control points alignment is supported at the moment only for areas",
  );

  const elevation = useAppSelector(selectControlPointsSheetElevation) ?? 0;

  // Define the state variables for the text field
  const [elevationErrorText, setElevationErrorText] = useState("");
  const [elevationTextField, setElevationTextField] = useState(
    elevation.toString(),
  );

  const controlPoint1 = useRef<Vector3Tuple | undefined>();
  const controlPoint2 = useRef<Vector3Tuple | undefined>();

  const validateAndStoreControlPointsPositions = useCallback(
    (
      isFirstAnchorPoint: boolean,
      position?: Vector3Tuple,
      otherPoint?: Vector3Tuple,
    ) => {
      const arePointsTooClose = checkIfTwoPointsForAlignmentAreTooClose(
        position,
        otherPoint,
      );

      setValidationErrorText(
        arePointsTooClose ? "Coordinates are too close" : undefined,
      );

      const validatedPosition = arePointsTooClose ? undefined : position;

      dispatch(
        isFirstAnchorPoint
          ? setControlPointsReferencePoint1(validatedPosition)
          : setControlPointsReferencePoint2(validatedPosition),
      );
    },
    [dispatch],
  );

  const changeReferencePoint1 = useCallback(
    (position?: Vector3Tuple) => {
      controlPoint1.current = position;

      validateAndStoreControlPointsPositions(
        true,
        position,
        controlPoint2.current,
      );
    },
    [validateAndStoreControlPointsPositions],
  );

  const changeReferencePoint2 = useCallback(
    (position?: Vector3Tuple) => {
      controlPoint2.current = position;

      validateAndStoreControlPointsPositions(
        false,
        position,
        controlPoint1.current,
      );
    },
    [validateAndStoreControlPointsPositions],
  );

  return (
    <Stack
      spacing={3}
      sx={{
        mt: "5px",
        ml: "10px",
        mr: "10px",
        width: 320,
      }}
    >
      <TruncatedFaroText
        variant="bodyM"
        sx={{
          pt: "15px",
          fontSize: "18px",
          fontWeight: 600,
        }}
      >
        Control points
      </TruncatedFaroText>

      <FaroText
        variant="bodyM"
        sx={{
          fontSize: "12px",
        }}
      >
        Add 2 control points and specify their coordinates
      </FaroText>

      <Stack spacing={1}>
        <FaroText
          variant="bodyM"
          sx={{
            fontSize: "12px",
            fontWeight: 600,
          }}
        >
          Elevation (m)
        </FaroText>

        <TextField
          fullWidth
          sx={{ height: "35px" }}
          text={elevationTextField}
          error={elevationErrorText}
          onTextChanged={(newText) => {
            setElevationTextField(newText);
            const newElevation = Number(newText);
            if (!newText.trim()) {
              setElevationErrorText("Elevation not defined");
              dispatch(setControlPointsSheetElevation(undefined));
            } else if (isNaN(newElevation)) {
              setElevationErrorText("Elevation must be a number");
              dispatch(setControlPointsSheetElevation(undefined));
            } else {
              setElevationErrorText("");
              if (Number(elevationTextField) !== newElevation) {
                dispatch(setControlPointsSheetElevation(newElevation));
              }
            }
          }}
        />
      </Stack>

      <TwoCoordinatesControl
        title="Point 1 coordinates (m)"
        changeReferencePoint={changeReferencePoint1}
      />
      <TwoCoordinatesControl
        title="Point 2 coordinates (m)"
        changeReferencePoint={changeReferencePoint2}
        validationErrorText={validationErrorText}
      />
    </Stack>
  );
}

type CoordinateEditControlProps = {
  /** label (x,y,z) to show left to edit control */
  label: string;

  /** method to send updated coordinate value to parent component */
  setNewValue(value?: number): void;

  /** Optional error text with error of both points validation, appearing only under "Y" of second point */
  validationErrorText?: string;
};

// debounce delay for coordinate control to prevent update store too often
const coordinateEditDebounceMsec = 300;

function CoordinateEditControl({
  label,
  setNewValue,
  validationErrorText,
}: CoordinateEditControlProps): JSX.Element {
  const [coordinateText, setCoordinateText] = useState("");
  const [errorText, setErrorText] = useState<string | undefined>();

  const [debounceChange] = useState(() =>
    debounce((value: number | undefined) => {
      setNewValue(value);
    }, coordinateEditDebounceMsec),
  );

  return (
    <Stack direction="column">
      <Stack
        direction="row"
        spacing={1}
        alignContent="center"
        sx={{
          height: "35px",
        }}
      >
        <FaroText
          variant="bodyM"
          alignContent="center"
          sx={{
            fontSize: "12px",
          }}
        >
          {label}
        </FaroText>

        <TextField
          fullWidth
          sx={{ height: "35px" }}
          text={coordinateText}
          error={errorText ?? validationErrorText}
          onTextChanged={(newText) => {
            // remove leading and trailing spaces as Number("  ") would return 0
            newText = newText.trim();
            setCoordinateText(newText);
            const newElevation = newText === "" ? undefined : Number(newText);
            if (newElevation === undefined || isNaN(newElevation)) {
              setErrorText("Coordinate must be a number");
              debounceChange(undefined);
            } else {
              setErrorText(undefined);
              if (newText !== coordinateText) {
                debounceChange(newElevation);
              }
            }
          }}
        />
      </Stack>
      {label === "X" && !!errorText && (
        <Box
          component="div"
          sx={{
            height: 20,
          }}
        />
      )}
    </Stack>
  );
}

type TwoCoordinatesControlProps = {
  /** text of title above two coordinates edit control */
  title: string;

  /** method to send updated reference point coordinates to parent component */
  changeReferencePoint(position?: Vector3Tuple): void;

  /** Optional error text with error of both points validation, appearing only under "Y" of second point */
  validationErrorText?: string;
};

function TwoCoordinatesControl({
  title,
  changeReferencePoint,
  validationErrorText,
}: TwoCoordinatesControlProps): JSX.Element {
  const pointCoordinateX = useRef<number | undefined>();
  const pointCoordinateY = useRef<number | undefined>();

  const handleChangeX = useCallback(
    (x: number | undefined) => {
      pointCoordinateX.current = x;
      // coordinate Z is inverted here  because following computation is done and used for coordinates picked in three.js
      // and expecting Z to be inverted.
      changeReferencePoint(
        x !== undefined && pointCoordinateY.current !== undefined
          ? [x, 0, -pointCoordinateY.current]
          : undefined,
      );
    },
    [changeReferencePoint, pointCoordinateY],
  );

  const handleChangeY = useCallback(
    (y: number | undefined) => {
      pointCoordinateY.current = y;
      changeReferencePoint(
        y !== undefined && pointCoordinateX.current !== undefined
          ? [pointCoordinateX.current, 0, -y]
          : undefined,
      );
    },
    [changeReferencePoint, pointCoordinateX],
  );

  return (
    <Stack spacing={1}>
      <FaroText
        variant="bodyM"
        sx={{
          fontSize: "12px",
          fontWeight: 600,
        }}
      >
        {title}
      </FaroText>

      <CoordinateEditControl label="X" setNewValue={handleChangeX} />

      <CoordinateEditControl
        label="Y"
        setNewValue={handleChangeY}
        validationErrorText={validationErrorText}
      />
    </Stack>
  );
}
