import { MultiRegistrationReport } from "@/registration-tools/utils/multi-registration-report";
import { redirectToViewer } from "@/utils/redirects";
import { Banner } from "@faro-lotv/flat-ui";
import { MaybeLoading } from "@faro-lotv/foundation";
import { IElementGenericPointCloudStream } from "@faro-lotv/ielement-types";
import {
  RegistrationRevision,
  RegistrationState,
  RevisionScanEntity,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { PointCloudsProcessingBanner } from "./point-clouds-processing-banner";
import { RegisteredStatusBanner } from "./registered-status-banner";

interface DataPreparationBannersProps {
  /** currently loaded revision */
  revision: RegistrationRevision;

  /** the scan-entities of the data-preparation page */
  scanEntities: RevisionScanEntity[];

  /** the point cloud streams for the scan-entities */
  pointCloudStreams?: IElementGenericPointCloudStream[];

  /** The registration report to show */
  registrationReport: MaybeLoading<MultiRegistrationReport>;

  /** Whether the registration is rerunning */
  isRegistrationRerunning: boolean;

  /** Callback function to be triggered from InspectAndPublishStatusBanner */
  onBackToRegistrationStepKey(): void;

  /** Callback function to be triggered from InspectAndPublishStatusBanner */
  onRerunRegistration(): void;
}

/** @returns the banners to show in the data-preparation page */
export function DataPreparationBanners({
  revision,
  scanEntities,
  pointCloudStreams,
  registrationReport,
  isRegistrationRerunning,
  onBackToRegistrationStepKey,
  onRerunRegistration,
}: DataPreparationBannersProps): JSX.Element {
  return (
    <>
      <DataPreparationStatusBanner
        revisionState={revision.state}
        registrationReport={registrationReport}
        isRegistrationRerunning={isRegistrationRerunning}
        onBackToRegistrationStepKey={onBackToRegistrationStepKey}
        onRerunRegistration={onRerunRegistration}
      />
      <PointCloudsProcessingBanner
        scanEntities={scanEntities}
        pointCloudStreams={pointCloudStreams}
      />
    </>
  );
}

type DataPreparationStatusBannerProps = {
  /** The current state of the registration. */
  revisionState: RegistrationState;
  /** The registration report to show */
  registrationReport: MaybeLoading<MultiRegistrationReport>;
  /** Whether the registration is rerunning */
  isRegistrationRerunning: boolean;
  /** Callback function to go to the registration step */
  onBackToRegistrationStepKey(): void;
  /** Callback function to rerun a registration */
  onRerunRegistration(): void;
};

function DataPreparationStatusBanner({
  revisionState,
  registrationReport,
  isRegistrationRerunning,
  onBackToRegistrationStepKey,
  onRerunRegistration,
}: DataPreparationStatusBannerProps): JSX.Element {
  const { projectApiClient } = useApiClientContext();
  switch (revisionState) {
    case RegistrationState.started:
      return (
        <Banner variant="info" title="Start registration">
          The registration has not started yet.
        </Banner>
      );
    case RegistrationState.cloudRegistrationStarted:
      return (
        <Banner variant="info" title="In progress">
          Registration is currently in progress. Please return later.
        </Banner>
      );
    case RegistrationState.userModified:
      return (
        <Banner variant="info" title="Editing">
          The registration has been modified manually.
        </Banner>
      );
    case RegistrationState.merged:
      return (
        <Banner
          variant="success"
          title="Dataset published"
          action={{
            label: "View in Sphere XG",
            action: () => redirectToViewer(projectApiClient.projectId),
          }}
        >
          The registration has been published, you can now view it in the Sphere
          Viewer.
        </Banner>
      );
    case RegistrationState.canceled:
      return (
        <Banner variant="error" title="Registration canceled">
          The registration has been canceled. Please restart the workflow.
        </Banner>
      );
    case RegistrationState.registered:
      return (
        <RegisteredStatusBanner
          registrationReport={registrationReport}
          isRegistrationRerunning={isRegistrationRerunning}
          onBackToRegistrationStepKey={onBackToRegistrationStepKey}
          onRerunRegistration={onRerunRegistration}
        />
      );
  }
}
