import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { ContextualToolbar } from "@faro-lotv/flat-ui";
import { selectSelectedScansRecursive } from "../store/data-preparation-ui/data-preparation-ui-selectors";
import { setSelectedEntityIds } from "../store/data-preparation-ui/data-preparation-ui-slice";

/** @returns A toolbar to provide contextual actions on the selected scans in the revision. */
export function RevisionContextualToolbar(): JSX.Element | null {
  const dispatch = useAppDispatch();
  const selectedScans = useAppSelector(selectSelectedScansRecursive);
  const selectedCount = selectedScans.length;

  return (
    <ContextualToolbar
      selectedCount={selectedCount}
      translateCountName="scanCount"
      elementName="scan"
      clearSelection={() => dispatch(setSelectedEntityIds([]))}
    />
  );
}
