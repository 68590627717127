import { EventType } from "@/analytics/analytics-events";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { Features, selectHasFeature } from "@/store/features/features-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectTags } from "@/store/tags/tags-selectors";
import { UNTAGGED, addNewTags } from "@/store/tags/tags-slice";
import {
  ActionTextField,
  FaroButton,
  FaroDialog,
  FaroDialogProps,
  FaroText,
  NO_TRANSLATE_CLASS,
  SnackbarKey,
  TextField,
  TruncatedFaroText,
  neutral,
  useToast,
} from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { generateGUID } from "@faro-lotv/foundation";
import {
  MutationAddAllowedLabel,
  createMutationAddAllowedLabel,
} from "@faro-lotv/service-wires";
import { Divider, List, ListItem, Skeleton, Stack } from "@mui/material";
import { createSelector } from "@reduxjs/toolkit";
import { useCallback, useState } from "react";
import { useCurrentProjectApiClient } from "../../common/project-provider/project-loading-context";

const MAX_TIME_BEFORE_LONG_PROCESSING_TOAST = 3000;

export type TagsManagementDialogProps = Pick<
  FaroDialogProps,
  "open" | "onClose"
>;

/** @returns a dialog to manage allowed tags in the current project */
export function TagsManagementDialog({
  open,
  onClose,
}: TagsManagementDialogProps): JSX.Element {
  const { handleErrorWithToast } = useErrorHandlers();
  const client = useCurrentProjectApiClient();
  const dispatch = useAppDispatch();

  const canEditTags = useAppSelector(selectHasFeature(Features.EditTags));

  const tags = useAppSelector(selectSortedAllowedTags);

  const [newTagName, setNewTagName] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  const { openToast, closeToast } = useToast();

  /** Adds a new tag to the project allowed tags */
  const addNewTag = useCallback(() => {
    setIsProcessing(true);
    Analytics.track(EventType.createNewTag);

    let longProcessingToastKey: SnackbarKey | undefined;

    // If the addition of a new tag takes more than MAX_TIME_BEFORE_LONG_PROCESSING_TOAST,
    // a toast is shown to inform the user that the operation is still in progress.
    const toastTimeout = setTimeout(() => {
      longProcessingToastKey = openToast({
        title: `The creation of tag "${newTagName}" is taking longer than expected. Please wait...`,
        variant: "info",
      });
    }, MAX_TIME_BEFORE_LONG_PROCESSING_TOAST);

    const newTag: MutationAddAllowedLabel["label"] = {
      id: generateGUID(),
      createdAt: new Date().toISOString(),
      name: newTagName,
      resourceId: client.projectId,
    };

    client
      .applyMutations([createMutationAddAllowedLabel(newTag)])
      .then(() => {
        dispatch(addNewTags([newTag]));
        setNewTagName("");
      })
      .catch((error) => {
        handleErrorWithToast({
          title: "Unable to create new tag",
          error,
        });
      })
      .finally(() => {
        setIsProcessing(false);
        clearTimeout(toastTimeout);
        if (longProcessingToastKey) {
          closeToast(longProcessingToastKey);
        }
      });
  }, [
    client,
    dispatch,
    handleErrorWithToast,
    newTagName,
    closeToast,
    openToast,
  ]);

  // TODO: Implement editing of tags https://faro01.atlassian.net/browse/SWEB-6257
  const validateNewTagName = useCallback(() => undefined, []);

  const updateTagName = useCallback(() => {
    // TODO: Implement editing of tags https://faro01.atlassian.net/browse/SWEB-6257
  }, []);

  const deleteTag = useCallback(() => {
    // TODO: Implement deletion of tags https://faro01.atlassian.net/browse/SWEB-6258
  }, []);

  return (
    <FaroDialog
      open={open}
      onClose={onClose}
      title="Tag Management"
      disabled={isProcessing}
      showXButton
      dark
    >
      <Stack gap={2} direction="column" sx={{ overflow: "auto" }}>
        <FaroText variant="bodyM" color="inherit">
          Add tag to your project to better organize the data.
        </FaroText>
        <AddTagField
          newTagName={newTagName}
          setNewTagName={setNewTagName}
          isProcessing={isProcessing}
          addNewTag={addNewTag}
        />
        <Divider sx={{ borderColor: neutral[800] }} />
        <Stack sx={{ overflow: "auto" }}>
          <List
            sx={{
              p: 0,
              display: "flex",
              flexDirection: "column",
              overflow: "auto",
            }}
          >
            {isProcessing && (
              <ListItem key="processing" sx={{ height: "36px" }}>
                <FaroText
                  variant="bodyM"
                  color="inherit"
                  sx={{ width: "100%" }}
                >
                  <Skeleton
                    sx={{ width: "100%", height: "2em", bgcolor: neutral[800] }}
                  />
                </FaroText>
              </ListItem>
            )}
            {tags.map((tag) => (
              <ListItem
                key={tag.id}
                sx={{ height: "36px" }}
                className={NO_TRANSLATE_CLASS}
              >
                {canEditTags ? (
                  <ActionTextField
                    inputText={tag.name}
                    dark
                    fullWidth
                    validate={validateNewTagName}
                    onConfirmButtonClick={updateTagName}
                    onDeleteButtonClick={() => deleteTag()}
                  />
                ) : (
                  <TruncatedFaroText variant="bodyM" color="inherit">
                    {tag.name}
                  </TruncatedFaroText>
                )}
              </ListItem>
            ))}
          </List>
        </Stack>
      </Stack>
    </FaroDialog>
  );
}

type AddTagFieldProps = {
  /** True if the app is creating a new tag in the backend */
  isProcessing: boolean;

  /** Name for the new tag to create */
  newTagName: string;

  /** Callback to update the name of the new tag to create */
  setNewTagName(name: string): void;

  /** Callback to trigger the creation of a new tag */
  addNewTag(): void;
};

function AddTagField({
  newTagName,
  setNewTagName,
  isProcessing,
  addNewTag,
}: AddTagFieldProps): JSX.Element {
  return (
    <TextField
      label="New tag"
      dark
      fullWidth
      placeholder="Insert name"
      text={newTagName}
      onTextChanged={setNewTagName}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          addNewTag();
        }
      }}
      InputProps={{
        endAdornment: (
          <AddTagButton
            isEnabled={!isProcessing && newTagName.length > 0}
            onClick={addNewTag}
          />
        ),
      }}
      sx={{
        padding: 0,
        height: "2.5rem",
        // Use && to have more priority over other mui classes
        "&& >input": {
          py: 0,
          height: "inherit",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        // Make the button the same height of the TextField
        "& >button": {
          height: "inherit",
          // Border only on the right
          borderRadius: "0 4px 4px 0",
        },
      }}
    />
  );
}

type AddTagButtonProps = {
  /** True if this button should be enabled */
  isEnabled: boolean;

  /** Callback called when the user click the button */
  onClick(): void;
};

/** @returns a custom button with a spinner when the app is processing */
function AddTagButton({ isEnabled, onClick }: AddTagButtonProps): JSX.Element {
  return (
    <FaroButton
      variant="primary"
      size="m"
      dark
      disabled={!isEnabled}
      onClick={onClick}
    >
      Add
    </FaroButton>
  );
}

/** @returns only the user allowed tags sorted by name */
const selectSortedAllowedTags = createSelector([selectTags], (tags) =>
  tags
    .filter((tag) => tag !== UNTAGGED)
    .sort((a, b) =>
      a.name.localeCompare(b.name, undefined, { sensitivity: "base" }),
    ),
);
