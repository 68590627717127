import { selectDefaultModeFor } from "@/modes";
import { useCurrentSceneIfAvailable } from "@/modes/mode-data-context";
import { selectBackgroundTasksBasedOnState } from "@/store/background-tasks/background-tasks-selector";
import { changeMode } from "@/store/mode-slice";
import { setActiveElement } from "@/store/selections-slice";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import { loadAnalysis } from "@/tools/analysis-saving-utils";
import { isPointCloudProcessingTask } from "@/utils/background-tasks";
import { NoTranslate } from "@faro-lotv/flat-ui";
import { GUID } from "@faro-lotv/foundation";
import { isIElementAnalysis } from "@faro-lotv/ielement-types";
import {
  TreeData,
  selectAllIElementsOfType,
  selectAncestor,
  selectIElement,
} from "@faro-lotv/project-source";
import { BackgroundTaskState } from "@faro-lotv/service-wires";
import { isEqual } from "@react-spring/shared";
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { NodeApi, Tree, TreeApi } from "react-arborist";
import { TreeProps } from "react-arborist/dist/module/types/tree-props";
import { useTreeContext } from "../tree-context";
import { TREE_NODE_HEIGHT } from "../tree-node";
import { OpenAreasLoader } from "./open-areas-loader";

type CaptureTreeProps = {
  /**
   * Set to true to disable all editing of the projects trough the tree view
   * TODO: To be implemented in https://faro01.atlassian.net/browse/SWEB-1950
   *
   * @default true
   */
  readOnly?: boolean;

  tree: TreeData[];
} & Pick<TreeProps<TreeData>, "children" | "height">;

/**
 * @returns The tree representation of the capture data of the project,
 * providing multiple actions to the user to interact with the capture data
 */
export function CaptureTree({
  tree,
  height,
  children,
}: CaptureTreeProps): JSX.Element {
  const treeRef = useRef<TreeApi<TreeData>>();
  const { openState, setOpenState } = useTreeContext();

  const { referenceElement, activeElement } =
    useCurrentSceneIfAvailable() ?? {};
  const activeElementId = referenceElement?.id ?? activeElement?.id;

  const store = useAppStore();
  const dispatch = useAppDispatch();

  const selectNode = useCallback(
    (nodes: Array<NodeApi<TreeData>>) => {
      if (nodes.length !== 1) return;

      const element = selectIElement(nodes[0].id)(store.getState());
      if (!element) return;

      const elementMode = selectDefaultModeFor(element)(store.getState());

      if (element.id === activeElementId) {
        return;
      }
      if (elementMode) {
        dispatch(changeMode(elementMode.targetMode));
        dispatch(setActiveElement(elementMode.element.id));
      } else {
        dispatch(setActiveElement(element.id));
      }
    },
    [activeElementId, dispatch, store],
  );

  const onToggle = useCallback(() => {
    if (treeRef.current?.openState) {
      setOpenState(treeRef.current.openState);
    }
  }, [setOpenState]);

  useOpenParentOnPointCloudProcessed(treeRef);

  useEffect(() => {
    const analyses = selectAllIElementsOfType(
      isIElementAnalysis,
      activeElementId,
    )(store.getState());
    if (analyses.length) {
      loadAnalysis(analyses, store.getState(), dispatch);
    }
  }, [activeElementId, dispatch, store]);

  return (
    <>
      {
        // Never translate the capture tree as there is mostly customer data in there and the capture tree will be
        // removed once we switch to the area navigation(see https://faro01.atlassian.net/browse/SWEB-4774)
      }
      <NoTranslate>
        <Tree<TreeData>
          ref={treeRef}
          data={tree}
          onSelect={selectNode}
          selection={activeElementId}
          openByDefault={false}
          onToggle={onToggle}
          // Disabling dnd features for now
          disableDrag
          disableDrop
          width="100%"
          height={height}
          rowHeight={TREE_NODE_HEIGHT}
          indent={24}
          initialOpenState={openState}
        >
          {children}
        </Tree>
      </NoTranslate>
      <OpenAreasLoader openState={openState} />
    </>
  );
}

/**
 * @param treeRef Tree reference to open the parent node on successful point cloud processing
 * Opens the parent node in the tree once the latest uploaded point cloud is processed
 */
function useOpenParentOnPointCloudProcessed(
  treeRef: MutableRefObject<TreeApi<TreeData> | undefined>,
): void {
  const [visibleNodeIds, setVisibleNodeIds] = useState<GUID[]>([]);

  useEffect(() => {
    if (treeRef.current) {
      setVisibleNodeIds(treeRef.current.visibleNodes.map((node) => node.id));
    }
  }, [treeRef, treeRef.current?.visibleNodes]);

  const succeededPointCloudTasks = useAppSelector(
    (state) =>
      selectBackgroundTasksBasedOnState(BackgroundTaskState.succeeded)(
        state,
      ).filter(isPointCloudProcessingTask),
    isEqual,
  );

  const latestPointCloud = useAppSelector(
    selectIElement(succeededPointCloudTasks[0]?.iElementId),
  );

  const parentNode = useAppSelector(
    selectAncestor(latestPointCloud, (parent) =>
      visibleNodeIds.includes(parent.id),
    ),
  );

  useEffect(() => {
    if (parentNode) {
      treeRef.current?.open(parentNode.id);
    }
  }, [parentNode, treeRef]);
}
