import { useCurrentScene } from "@/modes/mode-data-context";
import { Features, selectHasFeature } from "@/store/features/features-slice";
import { changeMode } from "@/store/mode-slice";
import { setLayerAreaElementId } from "@/store/modes/alignment-wizard-mode-slice";
import {
  selectActiveArea,
  selectListVisibleSheets,
} from "@/store/selections-selectors";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectHasWritePermission } from "@/store/user-selectors";
import {
  FaroIconButton,
  FaroMenu,
  FaroMenuProps,
  FaroPopover,
  FaroText,
  InfoIcon,
  neutral,
  PlusIcon,
} from "@faro-lotv/flat-ui";
import { assert } from "@faro-lotv/foundation";
import { IElement, isIElementAreaSection } from "@faro-lotv/ielement-types";
import { selectAncestor, State } from "@faro-lotv/project-source";
import { Stack, Tooltip } from "@mui/material";
import { useMemo, useState } from "react";
import {
  MultiLayerMenuItemGroup,
  selectMultiLayerMenuItemGroups,
} from "./multi-layer-menu-item-group";

export type MultiLayerMenuProps = Pick<
  FaroMenuProps,
  "open" | "anchorEl" | "onClose"
>;

/** @returns a PopOver to open from a menu to change the sheet rendered in the scene*/
export function MultiLayerMenu({
  open,
  anchorEl,
  onClose,
}: MultiLayerMenuProps): JSX.Element {
  const { availableSheets, activeSheets } = useCurrentScene();
  const activeArea = useAppSelector(selectActiveArea);

  const layerGroups = useAppSelector(
    selectMultiLayerMenuItemGroups(availableSheets),
  );

  // We assume all active sheets have the same parent area
  const haveSameParent = useAppSelector(
    selectHaveSameAncestor(activeSheets, isIElementAreaSection),
  );
  assert(haveSameParent, "All active sheets should have a parent area");

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [buttonRef, setButtonRef] = useState<HTMLButtonElement | null>(null);

  const dispatch = useAppDispatch();
  const hasWritePermission = useAppSelector(selectHasWritePermission);

  const shouldBeDarkStyle = true;

  const canManageLayersVisibility = useAppSelector(
    selectHasFeature(Features.LayersVisibility),
  );

  const listVisibleSheets = useAppSelector(selectListVisibleSheets);
  const numberVisibleSheetsInThisArea = useMemo(
    () =>
      activeSheets.reduce(
        (numberVisible: number, sheet) =>
          listVisibleSheets.includes(sheet.id)
            ? numberVisible + 1
            : numberVisible,
        0,
      ),
    [activeSheets, listVisibleSheets],
  );

  return (
    <FaroMenu
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: -2,
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      onClose={onClose}
      dark={shouldBeDarkStyle}
    >
      <Stack sx={{ minWidth: "291px" }}>
        <Stack direction="row" justifyContent="space-between" p={1.5}>
          <Stack direction="row" alignContent="center" alignItems="center">
            <FaroText variant="heading16" color={neutral[100]}>
              Layers
            </FaroText>

            {activeArea && (
              <FaroIconButton
                size="s"
                aria-label="layer-info"
                ref={setButtonRef}
                onClick={() => {
                  setIsPopoverOpen(true);
                }}
              >
                <InfoIcon sx={{ stroke: neutral[100] }} />
              </FaroIconButton>
            )}
          </Stack>

          {activeArea && hasWritePermission && (
            <Tooltip title="Add layer">
              <FaroIconButton
                size="s"
                aria-label="add-layer"
                onClick={() => {
                  dispatch(setLayerAreaElementId(activeArea.id));
                  dispatch(changeMode("importSheet"));
                }}
              >
                <PlusIcon sx={{ stroke: neutral[400] }} />
              </FaroIconButton>
            </Tooltip>
          )}
        </Stack>

        {layerGroups.map((group, index) => (
          <MultiLayerMenuItemGroup
            key={index}
            group={group}
            isFirstGroup={index === 0}
            selectedValue={
              canManageLayersVisibility ? undefined : activeSheets[0]?.id
            }
            isMoreThanOneVisibleSheet={numberVisibleSheetsInThisArea > 1}
          />
        ))}

        <FaroPopover
          anchorEl={buttonRef}
          open={isPopoverOpen}
          closeOnClickOutside
          dark={shouldBeDarkStyle}
          description={
            <>
              <p>Collection of sheets and overview maps.</p>
              <p>
                All sheets in the active area are listed here, but only the
                overview maps linked to the active point cloud are shown below.
              </p>
            </>
          }
          onClose={() => {
            setIsPopoverOpen(false);
          }}
          placement="bottom"
          title="Layers"
        />
      </Stack>
    </FaroMenu>
  );
}

/**
 * @param elements IElements to be checked
 * @param predicate Function to select the required ancestor
 * @returns true if selectAncestor would return the same value for all elements; true if elements is empty
 */
function selectHaveSameAncestor<Type extends IElement>(
  elements: IElement[],
  predicate: (el: IElement) => el is Type,
): (state: State) => boolean {
  return (state: State): boolean => {
    if (!elements.length) return true;
    let firstAncestor: Type | undefined = undefined;
    return elements.every((el) => {
      if (firstAncestor) {
        return selectAncestor(el, predicate)(state) === firstAncestor;
      }

      firstAncestor = selectAncestor(elements[0], predicate)(state);
      return true;
    });
  };
}
