import { EventType } from "@/analytics/analytics-events";
import { HelpPopover } from "@/components/ui/help-popover";
import {
  isRegistrationReportEmpty,
  MultiRegistrationReport,
} from "@/registration-tools/utils/multi-registration-report";
import { FaroButton } from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import {
  isDefinedAndLoaded,
  isLoading,
  MaybeLoading,
} from "@faro-lotv/foundation";
import {
  CaptureApiClient,
  RegistrationRevision,
  RegistrationState,
} from "@faro-lotv/service-wires";
import { Stack } from "@mui/material";

type ShowQualityReportButtonProps = {
  /** The revision to publish */
  revision: RegistrationRevision;

  /** The registration report of the active revision. */
  registrationReport: MaybeLoading<MultiRegistrationReport>;

  /** Callback, when the report should be opened */
  onShowReport(): void;
};

/**
 * @returns A button to show and hide the registration report.
 */
export function ShowQualityReportButton({
  revision,
  registrationReport,
  onShowReport,
}: ShowQualityReportButtonProps): JSX.Element | null {
  const isRegistered =
    revision.state === RegistrationState.registered ||
    revision.state === RegistrationState.merged;
  // Whether the registration was triggered by SCENE
  // In this case, we don't have a full registration report available
  const isByScene =
    revision.createdByClient === CaptureApiClient.scene ||
    (isDefinedAndLoaded(registrationReport) &&
      registrationReport.additionalData?.triggeredByClient ===
        CaptureApiClient.scene);
  const registeredByScene = isRegistered && isByScene;

  const isEnabled =
    isDefinedAndLoaded(registrationReport) &&
    !isRegistrationReportEmpty(registrationReport) &&
    !registeredByScene;

  if (
    (!registrationReport && !registeredByScene) ||
    (revision.state !== RegistrationState.registered &&
      revision.state !== RegistrationState.merged)
  ) {
    return null;
  }

  return (
    <Stack direction="row" alignItems="center">
      {registeredByScene ? (
        <HelpPopover
          variant="warning"
          title="Quality information unavailable"
          description="The registration was completed in SCENE, please check quality information there."
        />
      ) : null}

      <FaroButton
        variant="ghost"
        isLoading={isLoading(registrationReport)}
        disabled={!isEnabled}
        onClick={() => {
          Analytics.track(EventType.showQualityReport);
          onShowReport();
        }}
      >
        Show quality report
      </FaroButton>
    </Stack>
  );
}
